import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
// import { useAuth } from '../context/AuthContext';
import languageMapper from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { baseUrl, getValidAccessToken } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import { AuthContext } from "../context/AuthContext";


function AddWord({ isLoggedIn, onLogout }) {
  const { auth } = useContext(AuthContext);

  const { t, i18n } = useTranslation();

  const { id } = useParams();
  //   const { auth } = useAuth();
  const [phrase, setPhrase] = useState(null);
  const [word, setWord] = useState("");
  const [wordEnglish, setWordEnglish] = useState("");
  const [definition, setDefinition] = useState("");
  const [field, setField] = useState("");
  const [school, setSchool] = useState("");
  const [attribution, setAttribution] = useState("");
  const [status, setStatus] = useState("PND");
  const [pos, setPos] = useState("unknown");
  const [language, setLanguage] = useState("---");
  const [example, setExample] = useState("");
  const { interfaceLanguage, setInterfaceLanguage } = useContext(SharedStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [date_edited, setDate_edited] = useState("");
  const [date_accepted, setDate_accepted] = useState("");
  const [synonym, setSynonym] = useState("");
  const date = new Date(); // Current date and time
  const isoDate = date.toISOString(); // Convert to ISO 8601 format
  const [editor, setEditor] = useState("");
  const [synonyms, setSynonyms] = useState("");


  // const { auth } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchPhrase = async () => {
  //     const response = await fetch(baseUrl +`v1/addword`);
  //     const data = await response.json();
  //   //   data = data['terms!']
  //   setPhrase(data['terms!']);
  //   setDefinition(data['terms!'][0].definition);
  //   setField(data['terms!'][0].field);
  //   setSchool(data['terms!'][0].school);
  //   setAttribution(data['terms!'][0].attribution);
  //   setStatus(data['terms!'][0].status);
  //   setLanguage(data['terms!'][0].language);
  //   setExample(data['terms!'][0].example);

  // console.log(data,data['terms!'][0],phrase,word,definition,example) };
  //   fetchPhrase();
  // }, [id]);

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

  const handleSearch = async () => {
    console.log("---in handlesearch, taal is",language)
    setIsLoading(true); // Start loading
    const response = await fetch(
      baseUrl +
        `v1/soek/Eng/Eng/${encodeURIComponent(wordEnglish)}/false`
    );
    const data = await response.json();
    if (data["Terme in databasis"].length <1) {
      console.log('data__engelsgesoek',data["Terme in databasis"].length)
      handleSubmitEnglish();
      setIsLoading(false); // End loading
    } else {
      console.log('niewaarnie...data__engelsgesoek',data["Terme in databasis"].length)
      setIsLoading(false); // End loading
    }
  }

  const handleSubmitEnglish = async (e) => {
    sleep(500);
    console.log("---in handlesubmitenglish, taal is",language)
    setDate_edited(isoDate);
    console.log("iso and edited in handlesubmitenglish",isoDate,date_edited)
    setEditor(localStorage.getItem("userName"));
    console.log('dateeditor', date_edited,editor)
    if (status === "ACC") {setDate_accepted(isoDate)};
    const token = await getValidAccessToken(); // Get valid token
    const englishWord = wordEnglish;
    console.log("Jsonstringify handlesubmitenglish",JSON.stringify({isoDate,word: englishWord,wordEnglish,language: "Eng",field,school,attribution,status,editor,date_edited,date_accepted}))
    try {
      await fetch(baseUrl + "v1/addword/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          word: englishWord,
          wordEnglish,
          // synonyms,
          pos,
          field,
          school,
          language: "Eng",
          attribution,
          status,
          date_edited : getDate(),
          editor: localStorage.getItem("userName")
        }),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleSubmitWord = async (e) => {
    sleep(500);
    console.log("---in handlesubmitword, taal is",language)
    // setDate_edited(isoDate);

    console.log("iso and edited in handlesubmitword",isoDate,localStorage.getItem("userName"),date_edited,editor)


    const token = await getValidAccessToken(); // Get valid token

    // if (!wordEnglish) {
    //   setWordEnglish(word);  // Set wordEnglish to word if blank.  Temp solution todo: Ensure that wordEnglish is filled
    // }

    console.log("Jsonstringify handlesubmitword",JSON.stringify({isoDate,word,wordEnglish,field,school,attribution,status,editor,date_edited,date_accepted}))
    try {
      await fetch(baseUrl + "v1/addword/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept":  "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          word,
          wordEnglish:language === "Eng" ? word : wordEnglish,
          synonyms,
          pos,
          definition,
          example,
          field,
          school,
          language,
          attribution,
          status,
          "date_edited":getDate(),
          "editor":localStorage.getItem("userName"),
        }),
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
      navigate(0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    console.log("----------in handlesubmit, taal is",language)
    e.preventDefault();
      console.log("language_toets in handlesubmit",language)
    if (language !== "Eng"){
    handleSearch();}{
    handleSubmitWord();
  console.log("Los eers bostaande uit om fout te probeer kry. Moet afr stoor....")
  }
  }
  
  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Navbar /> */}
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "Addword")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}
      <div className="flex flex-col justify-center items-center p-3">
        <h2 className="text-3xl font-bold mb-2">{t("add")}</h2>
        {/* <h2 className="text-2xl mb-4">Add New Phrase</h2> */}
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 justify-center">
          <form
            onSubmit={handleSubmitWord}
            className="bg-white p-8 rounded shadow-lg"
          >
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("word")}:</strong>
              </label>
              <input
                type="text"
                value={word}
                onChange={(e) => {setWord(e.target.value);
                }}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("synonyms")}:</strong>
              </label>
              <input
                type="text"
                value={synonyms}
                onChange={(e) => {setSynonyms(e.target.value);
                }}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("language")}:</strong>
              </label>
              <select
                value={language}
                onChange={(e) => {setLanguage(e.target.value);
                  // if (language === "Eng" || language === "---" || language === ""){
                  //   setWordEnglish(word);
                  // }{}
                }}
                className="border p-2 w-full"
              >
                <option value="---">---</option>
                <option value="Eng">English</option>
                <option value="Afr">Afrikaans</option>
                <option value="Zul">isiZulu</option>
                <option value="Ses">Sesotho</option>
                <option value="Sep">Sepedi</option>
                <option value="Set">Setswana</option>
                <option value="Xit">XiTsonga</option>
                <option value="Sis">SiSwati</option>
                <option value="Nde">IsiNdebele</option>
                <option value="Xho">IsiXhosa</option>
                <option value="Tsh">TshiVenḓa</option>
                <option value="Por">Portuguese</option>
                <option value="Fre">French</option>
              </select>
            </div>
            {language != "Eng" && (
              <div className="mb-4">
                <label className="block text-gray-700">
                  <strong>English translation:</strong>
                </label>
                <input
                  type="text"
                  value={wordEnglish}
                  onChange={(e) => setWordEnglish(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
            )}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("pos")}:</strong>
              </label>
              <select
                value={pos}
                onChange={(e) => {
                  setPos(e.target.value);
                }}
                className="border p-2 w-full"
              >
                <option value="Unknown">{t("unknown")}</option>
                <option value="Noun">{t("noun")}</option>
                <option value="Verb">{t("verb")}</option>
                <option value="Adjective">{t("adjective")}</option>
                <option value="Pronoun">{t("pronoun")}</option>
                <option value="Adverb">{t("adverb")}</option>
                <option value="Preposition">{t("preposition")}</option>
                <option value="Conjunction">{t("conjunction")}</option>
                <option value="Article">{t("article")}</option>
                <option value="Interjection">{t("interjection")}</option>
              </select>
              </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("definition")}:</strong>
              </label>
              <input
                type="text"
                value={definition}
                onChange={(e) => setDefinition(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("example")}:</strong>
              </label>
              <input
                type="text"
                value={example}
                onChange={(e) => setExample(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("context")}:</strong>
              </label>
              <input
                type="text"
                value={field}
                onChange={(e) => setField(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("school")}:</strong>
              </label>
              <input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("attribution")}:</strong>
              </label>
              <input
                type="text"
                value={attribution}
                onChange={(e) => setAttribution(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("status")}:</strong>
              </label>
              <select
                value={status}
                // status={{ label: "Pending", value: "PND" }}
                onChange={(e) => {
                  setStatus(e.target.value);
                  if (language == "Eng") {
                    setWordEnglish(word);
                    console.log(word);
                    console.log("wordenglish...", wordEnglish);
                  }
                }}
                className="border p-2 w-full"
              >
                <option value="Non">--</option>
                <option value="ACC">{t("accepted")}</option>
                <option value="PND">{t("pending")}</option>
                <option value="REJ">{t("rejected")}</option>
                <option value="FLG">{t("flagged")}</option>
              </select>
            </div>
            {/*         
        <div className="mb-4">
          <label className="block text-gray-700"><strong>Status:</strong></label>
          <input
            type="text"
            value={status}
            onChange={(e) => {setStatus(e.target.value);
              if (language == "Eng"){
                setWordEnglish(word);  
                console.log(word);
                console.log("wordenglish...",wordEnglish)
                };
              if (language == "Eng"){
                setWordEnglish(word);  
                console.log(word);
                console.log("wordenglish...",wordEnglish)
                }}
            }
            className="border p-2 w-full"
          />          
        </div> */}
            <button
              type="submit"
              className="w-full bg-cyan-700 text-white py-2 px-4 rounded-lg hover:bg-cyan-800"
            >
              {t("add")}
            </button>
            <button
              type="reset"
              onClick={(e) => {
                // setWord(word);
                // setDefinition(definition);
                // setExample(example);
                window.history.back();
              }}
              className="w-1/2 mt-6 bg-rose-700 text-white py-2 px-4 rounded-lg hover:bg-rose-800"
            >
              {t("cancel")}
            </button>
          </form>
        </div>
        <div className="flex flex-col justify-center items-center p-4">
          <Link to={"/"}>
            <img src={Logo} alt="Logo" width="100" height="100" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddWord;
