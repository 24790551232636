import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import languageMapper from "../shared";
import statusMapper from "../shared2";
import Logo from "../logo512.png";
import { MathJax } from "better-react-mathjax";
import { useTranslation } from "react-i18next";
import "../i18n";
import { baseUrl, getValidAccessToken } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import { useNavigate } from "react-router-dom";

// const confirmDelete = () => {
//   setItems(items.filter((item) => item !== itemToDelete));
//   setShowConfirm(false);
// };

function PhraseDetail({ isLoggedIn, onLogout }) {
  const { auth } = useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  //   const { auth } = useAuth();
  const [phrase, setPhrase] = useState(null);
  //   const [statusvar,setStatusvar] = useState(true)
  const { interfaceLanguage, setInterfaceLanguage } =
    useContext(SharedStateContext);
  const [sourceLanguage, setSourceLanguage] = useState("Eng");
  const [targetLanguage, setTargetLanguage] = useState("All");
  const [partialMatch, setPartialMatch] = useState(false);
  const [items, setItems] = useState(["Item 1", "Item 2", "Item 3"]); // Example items
  const [showConfirm, setShowConfirm] = useState(false); // State to track confirmation dialog
  const [itemToDelete, setItemToDelete] = useState(null); // Track which item is being deleted

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowConfirm(true);
  };

  const confirmDelete = async () => {
    const token = await getValidAccessToken(); // Get valid token

    try {
      await fetch(baseUrl + `v1/deleteword/${id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.accessToken,
        },
      });
      // history.push('/')
      navigate(-1);
      // window.location.href = '/root';
    } catch (error) {
      console.error("Error:", error);
      navigate("/login")
    }
    setItems(items.filter((item) => item !== itemToDelete));
    setShowConfirm(false);
  };

  const cancelDelete = () => {
    setShowConfirm(false);
    setItemToDelete(null);
  };

  useEffect(() => {
    const fetchPhrase = async () => {
      const response = await fetch(baseUrl + `v1/soek_woord_id/${id}`);
      const data = await response.json();
      setPhrase(data["terms!"]);
      // console.log("Data:",phrase[0].wordEnglish)
    };
    fetchPhrase();
  }, [id]);

  // const handleDelete = async () => {
  //   try {
  //     await fetch(`http://localhost:8000/v1/deleteword/${id}`, {
  //       method: 'DELETE',
  //       headers: {
  //       //   'Authorization': `Bearer ${auth.accessToken}`,
  //       },
  //     });
  //     window.location.href = '/';
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  if (!phrase) return <div>Loading...</div>;
  console.log(phrase[0].language);
  //   setStatusvar(phrase[0].status == 'ACC')

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Navbar /> */}
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "PhraseDetails")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}     
      <div className="flex flex-col justify-between items-center p-3">
        <MathJax>
          <h1 className="text-3xl font-bold mb-2 justify-self-center text-center">
            {t("termdetail")}
          </h1>
          {/* <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6"> */}

          <div className="flex flex-col justify-between ">
            <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3 border-2 border-solid border-red">
              <button
                onClick={() => navigate(-1)}
                className="ml-0 bg-gray-500 text-white py-2 by-4 px-4 rounded-lg hover:bg-gray-600"
              >
                {t("back")}
              </button>
              <>{
                auth.userName !== null && 
                <Link to={`/translate/${phrase[0]._id}`}>
                  <button
                    className=" bg-cyan-700 text-white ml-4 
               py-2 px-4 rounded-lg  hover:bg-cyan-800"
                  >
                    {t("translate")}
                  </button>
                </Link>
              }</>
              <h2 className="text-2xl">{phrase[0].word}</h2>
              <p>
                <strong>{t("language")}:</strong>{" "}
                {languageMapper(phrase[0].language)}
              </p>
              {/* {(auth.userRole === "administrator"|| auth.userRole === "editor") ?  */}
              {(phrase[0].language !== "Eng") ? 
              <p>
                <strong>English:</strong> {phrase[0].wordEnglish}
              </p> : 
              null}
              <p>
                <strong>{t("pos")}:</strong> {phrase[0].pos}
              </p>
              <p>
                <strong>{t("context")}:</strong> {phrase[0].field}
              </p>
              <p>
                <strong>{t("school")}:</strong> {phrase[0].school}
              </p>

              {
                phrase[0].school
                  ? console.log("School")
                  : console.log("Is daar")
                // phrase[0].school = "None";
              }
              <p>
                <strong>{t("definition")}:</strong> {phrase[0].definition}
              </p>
              <p>
                <strong>{t("attribution")}:</strong>{" "}
                <span className="mb-3 text-gray-500 dark:text-gray-400 text-sm">
                  {" "}
                  {phrase[0].attribution}
                </span>
              </p>
              <p>
                <strong>{t("example")}:</strong> {phrase[0].example}
              </p>
              <div>
                {phrase[0].status === "ACC" ? (
                  <div>
                    <p>
                      <strong>{t("status")}:</strong>{" "}
                      <span className=" mb-3 text-blue-500 dark:text-blue-400 text-sm ">
                        {" "}
                        {t(phrase[0].status)}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      <strong>{t("status")}:</strong>{" "}
                      <span className=" mb-3 text-red-500 dark:text-red-400 text-sm ">
                        {" "}
                        {t(phrase[0].status)}
                      </span>
                    </p>
                  </div>
                )}
              </div>

              <div className="flow-root mt-3 ">
                <>{(auth.userRole === 'editor' ||auth.userRole === 'administrator') && 
                  //   auth.accessToken &&
                  <Link to={`/update/${phrase[0]._id}`}>
                    <button className="float-left ml-0 bg-cyan-700 text-white py-2 px-4 mt-5 rounded-lg hover:bg-cyan-800">
                      {t("update")}{" "}
                    </button>
                  </Link>
                }</>
                {/* Delete only available to administrator.  Consider editor? */}
                <>{(auth.userRole === 'administrator' || auth.userRole === 'administrator') && <button
                  onClick={() => handleDeleteClick(phrase[0]._id)}
                  className="float-right mr-4 mt-5 ml-4 bg-rose-700 text-white py-2 px-4 rounded-lg hover:bg-rose-800"
                >
                  {t("delete")}
                </button>}</>
              </div>

              {showConfirm && (
  <>
    {/* Modal overlay */}
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      {/* Modal content */}
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3">
        <div className="p-4">
          {/* <h3 className="text-lg font-semibold text-gray-800">{t("deleteconfirma")}</h3> */}
        </div>
        <div className="p-4">
          <p className="text-gray-700">
            {t("deleteconfirma")} <b>{phrase[0].word}</b> {t("deleteconfirmb")} <b>{phrase[0].field}</b> {t("deleteconfirmc")} <b>{languageMapper(phrase[0].language)}</b> {t("deleteconfirmd")}
          </p>
        </div>
        {/* Modal footer with buttons */}
        <div className="flex justify-end p-4 border-t border-gray-300">
          <button
            className="bg-gray-700 text-white py-2 px-4 rounded-lg hover:bg-gray-800 mr-2"
            onClick={cancelDelete}
          >
            {t("no")}
          </button>
          <button
            className="bg-rose-700 text-white py-2 px-4 rounded-lg hover:bg-rose-800"
            onClick={confirmDelete}
          >
            {t("yes")}
          </button>
        </div>
      </div>
    </div>
  </>
)}

            </div>
          </div>
        </MathJax>
      </div>
      <div className="flex flex-col justify-center items-center p-4">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" width="100" height="100" />
        </Link>
      </div>
    </div>
  );
}

export default PhraseDetail;
