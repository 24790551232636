import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import languageMapper from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { create } from "zustand";
import { MathJax } from "better-react-mathjax";
import { baseUrl } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";

function Login() {
  const { auth } = useContext(AuthContext); // Access the auth state
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(baseUrl + "v1/login/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // mode: 'cors',  // Ensure CORS mode is enabled
      // credentials: 'include',  // Optional, if credentials are needed
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();
    if (response.ok) {
      login(data.access, data.refresh, data.username, data.role);
        localStorage.setItem('accessToken', data.access);
        console.log("Wat gestoor word: ", data.refresh)
        localStorage.setItem('refreshToken', data.refresh);
        localStorage.setItem('username', data.username);
        localStorage.setItem('userrole', data.role);
        auth.userName =  data.username;
        auth.userRole = data.role;
      console.log(
        "data!!!!: ",
        data.username,
        data.role,
        data.access,
        data.refresh
      );
      console.log("Sukses: ", username);
      navigate(-1);
    } else {
      console.error("Login failed");
      alert("Login failed!")
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  // useEffect(() => {
  //   const fetchUserRole = async () => {
  //     if (auth?.accessToken) {
  //       const response = await fetch(baseUrl + 'v1/user-role/', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${auth.accessToken}`,
  //         },
  //       });
  //       const data = await response.json();
  //       setUserRole(data.role);
  //       console.log("setUserRole in fetchUserRole", data.role)
  //     }
  //   };

  //   fetchUserRole();
  // }, [auth]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "Login")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}      

      <div className="flex flex-col justify-center items-center p-2">
        <h1 className="text-4xl font-bold mb-6">
          <Link to={"/"}>
            {/* <a href="/" className="inline-block">  */}
            {t("login")}
            {/* </a> */}
          </Link>
        </h1>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Username"
              className="border border-2 p-2 mb-4 w-full max-w-3xl rounded-lg"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              className="border border-2 p-2 mb-4 w-full max-w-3xl rounded-lg"
              placeholder="Password"
            />
            <button
              type="submit"
              className="w-full bg-cyan-700 text-white py-2 mt-4 px-4 rounded-lg hover:bg-cyan-800"
            >
              {t("login")}
            </button>
          </form>
          <div className="flex justify-between mt-10">
          <form>
            <Link to={"/register"}>
              <button
                type="submit"
                className="flex flexcol items-center mt-10 w-1/8 justify-center bg-cyan-900 text-white py-2 mt-4 px-4 rounded-lg hover:bg-cyan-800"
              >
                {t("register")}
              </button>
            </Link>
          </form>
          <form>
            <Link to={"/password-reset"}>
              <button
                type="submit"
                className="flex flexcol items-center mt-10 w-1/16 justify-center bg-cyan-900 text-white py-2 mt-4 px-4 rounded-lg hover:bg-cyan-800"
              >
                {t("forgot_password")}
              </button>
            </Link>
          </form>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-4 justify-center items-center p-4">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" width="100" height="100" />
        </Link>
      </div>
    </div>
  );
}

export default Login;
