import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useNavigate } from "react-router-dom";
import languageMapper from "../shared";

function NoResultsModal({
  isOpen,
  onClose,
  searchTerm,
  sourceLanguage,
  targetLanguage,
  gtTerm,
  onSubmit,
  onKeyDown,
}) {
  const [suggestedTerm, setSuggestedTerm] = useState("");
  const [requestYes, setRequestYes] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status

  const handleSubmit = () => {
    console.log("Submitting term...");
    onSubmit(suggestedTerm);

    setIsSubmitted(true); // Mark form as submitted
    console.log("Form submitted, displaying success message...");

    setTimeout(() => {
      console.log("Closing modal after delay...");
      onClose(); // Call the onClose after delay
      setIsSubmitted(false);
    }, 2500); //
  };

  const handleModalKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  // function handleRequestEnter(e){
  //   var key = 'which' in e ? e.which : e.keyCode;
  //   if (key == 13) {
  //     handleSubmit
  //   }
  //  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById("root")}
      className="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-5/12 items-center">
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3 border-2 border-solid border-red">
          <button
            onClick={onClose} // Close modal
            className="ml-0 bg-gray-500 text-white py-2 by-4 px-4 rounded-lg hover:bg-gray-600"
          >
            {t("back")}
          </button>
          <div className="p-4 items-center">
            <h2 className="text-lg font-semibold text-gray-800 items-center">
              {isSubmitted ? t("submit_request") : t("term_not_found")}
              {/* {isSubmitted ? "ingedien" : {t('term_not_found')}} */}
            </h2>
          </div>
          <div className="p-4 m-4 ">
            {isSubmitted ? (
              <div className="text-green-600 font-semibold">
                {t("submitsuccess")} {/* Success message */}
              </div>
            ) : targetLanguage === "All" ? (
              <>{t("notranslation")}</>
            ) : (
              <>
                <p>
                  {t("theterm")} "{searchTerm}" {t("notfound")}
                </p>
                  <div className="text-cyan-800">
                    {console.log("Gemini:", gtTerm)}
                    {gtTerm !== "undefined" ? (gtTerm.length > 0
                      ? `(Google Translate ${t("suggests")} "${gtTerm}".)`
                      : <div className="inline-block mt-0 h-4 w-4 animate-spin rounded-full text-cyan-900 border-2 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      />) : <div className="invisible">None found</div>
                    }
                  </div>
                <p>{t("liketorequest")}</p>

                <div className="mt-4 mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={requestYes}
                      onChange={() => setRequestYes(!requestYes)}
                      className="form-checkbox h-5 w-5 text-cyan-700 accent-cyan-700"
                    />
                    <span className="ml-2 text-lg font-medium">
                      {t("request_translation")}
                    </span>
                  </label>
                </div>
                <p className="text-gray-700">
                  <label htmlFor="suggestedTerm">
                    {t("suggest_translationa")} {languageMapper(targetLanguage)}
                    {t("suggest_translationb")} {t("ifsotype")}
                  </label>
                </p>
                <input
                  type="text"
                  id="suggestedTerm"
                  value={suggestedTerm}
                  onChange={(e) => setSuggestedTerm(e.target.value)}
                  onKeyDown={handleModalKeyDown}
                  className="border-2 border-gray-500 rounded  p-2 w-2/3 "
                  disabled={isSubmitted} // Disable input after successful submit
                />
                <button
                  onClick={handleSubmit}
                  className="bg-cyan-700 ml-8 text-white p-2 mt-4 rounded hover:bg-cyan-800"
                  disabled={isSubmitted} // Disable button after successful submit
                >
                  {t("submit_request")}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NoResultsModal;

//   return (
//     <Modal isOpen={isOpen} onRequestClose={onClose}
//     className="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//             <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3 items-center">
//             <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3 border-2 border-solid border-red">
//               <button
//                 onClick={onClose}
//                 className="ml-0 bg-gray-500 text-white py-2 by-4 px-4 rounded-lg hover:bg-gray-600"
//               >
//                 {t("back")}
//               </button>
//             <div className="p-4 border-b border-gray-300 items-center">
//       <h2 className="text-lg font-semibold text-gray-800 items-center">Term not found</h2>
//       </div>
//       <div className="p-4 m-4 border-b border-gray-300">
//       <p>
//         The term "{searchTerm}" was not found in the selected language. Would you like to request a translation?
//       </p>
//       <div>
//       <div className="mb-4">
//             <label className="inline-flex items-center">
//               <input
//                 type="checkbox"
//                 checked={requestYes}
//                 onChange={() => setRequestYes(!requestYes)}
//                 className="form-checkbox h-5 w-5 text-cyan-700 accent-cyan-700"
//               />
//               <span className="ml-2 text-lg font-medium">{t("request_translation")}</span>
//             </label>
//           </div>

//           <p className="text-gray-700">
//         <label htmlFor="suggestedTerm">
//           Do you want to suggest a possible term in {targetLanguage}? If so, type it here:
//         </label>
//         </p>
//       </div>
//         <input
//           type="text"
//           id="suggestedTerm"
//           value={suggestedTerm}
//           onChange={(e) => setSuggestedTerm(e.target.value)}
//           className="border p-2 w-full"
//         />
//       </div>
//       <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 mt-4 rounded">
//         Submit Request
//       </button>
//       </div>
//       </div>
//     </Modal>
//   );
// }

// export default NoResultsModal;
