import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
// import { useAuth } from '../context/AuthContext';
import languageMapper from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { baseUrl, getValidAccessToken } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import { AuthContext } from "../context/AuthContext";


function TranslateWord({ isLoggedIn, onLogout }) {
  const { auth } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  //   const { auth } = useAuth();
  const [phrase, setPhrase] = useState(null);
  const [word, setWord] = useState("");
  const [pos, setPos] = useState("");
  const [wordEnglish, setWordEnglish] = useState("");
  const [definition, setDefinition] = useState("");
  const [field, setField] = useState("");
  const [school, setSchool] = useState("");
  const [attribution, setAttribution] = useState("");
  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState("");
  const [example, setExample] = useState("");
  const { interfaceLanguage, setInterfaceLanguage } = useContext(SharedStateContext);
  const [tword, settWord] = useState("");
  const [tpos, settPos] = useState("");
  const [tdefinition, settDefinition] = useState("");
  const [tfield, settField] = useState("");
  const [tschool, settSchool] = useState("");
  const [tattribution, settAttribution] = useState("");
  const [tstatus, settStatus] = useState("PND");
  const [tlanguage, settLanguage] = useState("Eng");
  const [texample, settExample] = useState("");
  const [tuser, settUser] = useState(auth.userName);
  // setPos(tpos);
  // settUser(auth.userName);

  const handleWordClick = (word) => {
    settWord(word);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPhrase = async () => {
      const response = await fetch(baseUrl + `v1/soek_woord_id/${id}`);
      const data = await response.json();
      //   data = data['terms!']
      setPhrase(data["terms!"]);
      setWord(data["terms!"][0].word);
      setWordEnglish(data["terms!"][0].wordEnglish);
      settPos(data["terms!"][0].pos);
      setDefinition(data["terms!"][0].definition);
      setField(data["terms!"][0].field);
      setSchool(data["terms!"][0].school);
      setAttribution(data["terms!"][0].attribution);
      setStatus(data["terms!"][0].status);
      setLanguage(data["terms!"][0].language);
      setExample(data["terms!"][0].example);
      console.log(data, data["terms!"][0], phrase, word, definition, example);
    };
    fetchPhrase();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Wat ons kry", JSON.stringify({ word, definition, example, tuser, tword }));
    // settStatus("PND")
    // settUser(auth.userName)
    const token = await getValidAccessToken(); // Get valid token

    try {
      await fetch(baseUrl + `v1/translate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          wordEnglish,
          tword,
          tpos,
          tdefinition,
          texample,
          tfield,
          tschool,
          tlanguage,
          tattribution,
          tstatus,
          tuser,
        }),
      });
      navigate(`/phrase/${id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!phrase) return <div>Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Navbar /> */}
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "Translate")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}
      <div className="flex flex-col justify-center items-center p-3">
        <h2 className="text-3xl font-bold mb-2">{t("translate")}</h2>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 justify-center">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("newlanguage")}:</strong>
              </label>
              <select
                id="tlanguage"
                value={tlanguage}
                onChange={(e) => settLanguage(e.target.value)}
                className="w-full p-2 border rounded-lg"
              >
                {/* <option value="All">{t("alllanguages")}</option> */}
                <option value="Eng">English</option>
                <option value="Afr">Afrikaans</option>
                <option value="Zul">isiZulu</option>
                <option value="Ses">Sesotho</option>
                <option value="Sep">Sepedi</option>
                <option value="Set">Setswana</option>
                <option value="Xit">XiTsonga</option>
                <option value="Sis">SiSwati</option>
                <option value="Nde">IsiNdebele</option>
                <option value="Xho">IsiXhosa</option>
                <option value="Tsh">TshiVenḓa</option>
                <option value="Por">Portuguese</option>
                <option value="Fre">French</option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="word" className="block text-gray-700">
                <strong>
                  {t("sourceword")}:{"  "}
                </strong>
                <span onClick={() => handleWordClick(word)}>
                  <i>{word}</i>
                </span>
              </label>
              {/* <label className="block text-gray-700"><strong>{t("sourceword")}:  </strong><i>{ word }</i></label> */}
              <input
                type="text"
                value={tword}
                id="word"
                // placeholder={t("enter")}
                placeholder={t("translatedterm")}
                onChange={(e) => settWord(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("definition")}:{"  "}
                </strong>
                <span onClick={() => settDefinition(definition)}>
                  <i>{definition}</i>
                </span>
              </label>
              <input
                type="text"
                value={tdefinition}
                placeholder={t("definition")}
                onChange={(e) => settDefinition(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("example")}:{"  "}
                </strong>
                <span onClick={() => settExample(example)}>
                  <i>{example}</i>
                </span>
              </label>
              <input
                type="text"
                value={texample}
                placeholder={t("example")}
                onChange={(e) => settExample(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("context")}:{"  "}
                </strong>
                <span onClick={() => settField(field)}>
                  <i>{field}</i>
                </span>
              </label>
              <input
                type="text"
                value={tfield}
                placeholder={t("context")}
                onChange={(e) => settField(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("school")}:{"  "}
                </strong>
                <span onClick={() => settSchool(school)}>
                  <i>{school}</i>
                </span>
              </label>
              <input
                type="text"
                value={tschool}
                placeholder={t("school")}
                onChange={(e) => settSchool(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("attribution")}:{"  "}
                </strong>
                <span onClick={() => settAttribution(attribution)}>
                  <i>{attribution}</i>
                </span>
              </label>
              <input
                type="text"
                value={tattribution}
                placeholder={t("attribution")}
                onChange={(e) => settAttribution(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            {/* <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("status")}:{"  "}
                </strong>
              </label>
              <select
                id="status"
                value={tstatus}
                onChange={(e) => settStatus(e.target.value)}
                className="w-full p-2 border rounded-lg"
              >
                <option value="ACC">{t("accepted")}</option>
                <option value="PND">{t("pending")}</option>
                <option value="REJ">{t("rejected")}</option>
                <option value="FLG">{t("flagged")}</option>
              </select>
            </div> */}

            <button
              type="submit"
              className="w-full bg-cyan-700 text-white py-2 px-4 rounded-lg hover:bg-cyan-800"
            >
              Save
            </button>
            <button
              type="reset"
              onClick={(e) => {
                window.history.back();
              }}
              className="w-1/4 mt-6 bg-rose-700 text-white py-2 px-4 rounded-lg hover:bg-rose-800"
            >
              Cancel
            </button>
          </form>
        </div>
        <div className="flex flex-col justify-center items-center p-4">
          <a href="/" className="inline-block">
            <img src={Logo} alt="Logo" width="100" height="100" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TranslateWord;
