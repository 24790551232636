import React, { useState, useContext } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./i18n";
import SharedStateContext from './context/sharedstate';
import { AuthContext } from "./context/AuthContext";



const Navbaranonuser = ({isLoggedIn, onLogout }) => {
  const { auth } = useContext(AuthContext);

  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  const { t, i18n } = useTranslation();
  const {interfaceLanguage, setInterfaceLanguage } = useContext(SharedStateContext);
  // Array containing navigation items
  const navItems = [
    { id: 1, text: <Link to="/about" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"> {t("about")} </Link> },
    { id: 2, text: <Link to="/contact" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"> {t("contact")} </Link> },
    // { id: 3, text: <Link to="/advanced" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"> {t("advanced")} </Link> },
    {
      id: 3,
      text: 
        localStorage.getItem("bladsy") === "LandingPage" ? 
      (
        <Link
          to="/advanced"
          className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"
        >
          {" "}
          {t("advanced")}{" "}
        </Link>
      ) : (
        <Link
          to="/"
          className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"
        >
          {" "}
          {t("search")}{" "}
        </Link>
      ),
    },
    { id: 4, text: auth.userName == null ? (<Link to="/login" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"> {t("login")} </Link>) : (<Link to="/logout" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-blue-500"> {t("logout")} </Link>) },
  //   { id: 5, text:  isLoggedIn ? (
  //     < onClick={onLogout} className="ml-4 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600">
  //       Logout
  //     </>
  //   ) : (
  //     <Link to="/login" className="ml-4 bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600">
  //       Login
  //     </Link>
  //   )
  // },
  ];
    console.log(auth.userName);


  return (
    <div className='bg-white shadow-lg flex justify-between items-center h-12 max-w-5xl mx-auto px-3'>
      {/* Logo */}

      {/* Desktop Navigation */}
      <ul className='hidden lg:flex'>
        {navItems.map(item => (
          <li
            key={item.id}
            className='text-gray-800 bg-white p-2 hover:bg-gray-100 rounded-md m-2 cursor-pointer duration-300 hover:text-black'
          >
            {item.text}
          </li>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block lg:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed lg:hidden left-0 top-0 w-[40%]  mt-12 bg-white ease-in-out duration-200 lg:space-y-0  space-y-2 overflow-auto'
            : 'ease-in-out w-[60%] duration-2 fixed top-0 bottom-12 left-[-100%]'
        }
      >
        {/* Mobile Logo */}

        {/* Mobile Navigation Items */}
        {navItems.map(item => (
          <li
            key={item.id}
            className='bg-gray-100 text-gray-800 p-2 rounded-md hover:bg-white-200 shadow-lg duration-300 hover:text-black cursor-pointer border-gray-600'
          >
            {item.text}
          </li>
        ))}
      </ul>

{auth.userName}

      <select
              value={interfaceLanguage}
              onChange={(e) => {setInterfaceLanguage(e.target.value);
                i18n.changeLanguage(e.target.value);
                localStorage.setItem("taal",e.target.value);
              }}
              className="border p-2 rounded-lg mr-4"
            >
              <option value="en">English</option>
              <option value="af">Afrikaans</option>
              <option value="zu">isiZulu</option>
              <option value="st">Sesotho</option> 
              <option value="nso">Sepedi</option>    
              <option value="tn">Setswana</option>  
              <option value="ts">XiTsonga</option>  
              <option value="ss">SiSwati</option>   
              <option value="nr">IsiNdebele</option>
              <option value="xh">IsiXhosa</option>  
              <option value="ve">TshiVenḓa</option> 
              <option value="pt">Portuguese</option>
              <option value="fr">French</option>
              </select>
    </div>
  );
};

export default Navbaranonuser;
