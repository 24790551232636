import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { useAuth } from '../context/AuthContext';
import { baseUrl, getValidAccessToken } from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import SharedStateContext from "../context/sharedstate";
// import { AuthContext } from "../context/AuthContext";

import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";

function UpdateWord({ isLoggedIn, onLogout }) {
  // const { auth } = useContext(AuthContext);

  const { t } = useTranslation();
  const { interfaceLanguage, setInterfaceLanguage } =
    useContext(SharedStateContext);
  const { id } = useParams();
  //   const { auth } = useAuth();
  const [phrase, setPhrase] = useState(null);
  const [word, setWord] = useState("");
  const [definition, setDefinition] = useState("");
  const [field, setField] = useState("");
  const [school, setSchool] = useState("");
  const [attribution, setAttribution] = useState("");
  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState("");
  const [example, setExample] = useState("");
  const [editor, setEditor] = useState("");
  const [date_edited, setDate_edited] = useState("");
  const [date_accepted, setDate_accepted] = useState("");
  const [pos, setPos] = useState("");
  const [synonyms, setSynonyms] = useState("");

  const date = new Date(); // Current date and time
  const isoDate = date.toISOString(); // Convert to ISO 8601 format
  // const [interfaceLanguage, setInterfaceLanguage] = useState("Eng");

//  const isAccessTokenExpired = (token) => {
//    if (!token) return true;  // If no token, treat as expired
 
//    const tokenParts = JSON.parse(atob(token.split('.')[1]));
//    const now = Math.floor(Date.now() / 1000);
//    console.log("tokenoets:",tokenParts,now,tokenParts.exp < now) 
//    return tokenParts.exp < now;  // Check if the token's expiration time is in the past
//  };
      
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPhrase = async () => {
      const response = await fetch(baseUrl + `v1/soek_woord_id/${id}`);
      const data = await response.json();
      console.log("rou ontvangs:",data)
      setPhrase(data["terms!"]);
      setWord(data["terms!"][0].word);
      setDefinition(data["terms!"][0].definition);
      setField(data["terms!"][0].field);
      setSchool(data["terms!"][0].school);
      setAttribution(data["terms!"][0].attribution);
      setStatus(data["terms!"][0].status);
      setLanguage(data["terms!"][0].language);
      setPos(data["terms!"][0].pos);
      setSynonyms(data["terms!"][0].synonyms);
      setEditor(data["terms!"][0].editor);
      setDate_accepted(data["terms!"][0].date_accepted);
      setDate_edited(data["terms!"][0].date_edited);
    };
    fetchPhrase();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDate_edited(isoDate);
    console.log(
      JSON.stringify({ pos, word, definition, example, editor, date_edited })
    );
    console.log("Wat gestuur word: ",JSON.stringify({
          word,
          definition,
          example,
          field,
          pos,
          synonyms,
          school,
          language,
          attribution,
          status,
          editor,
          date_accepted,
          date_edited,
        }))
    try {
       console.log("AccessToken: ",localStorage.getItem("accessToken"))
       const token = await getValidAccessToken(); // Get valid token

      //  console.log("AccessTokenvalid: ",isAccessTokenExpired(localStorage.getItem("accessToken")))
      await fetch(baseUrl + `v1/updateword/${id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          word,
          definition,
          example,
          field,
          pos,
          synonyms,
          school,
          language,
          attribution,
          status,
          editor,
          date_accepted,
          date_edited,
        }),
      });
      navigate(`/phrase/${id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!phrase) return <div>Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Navbar /> */}
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "UpdateWord")}
      {localStorage.getItem("userName") === null && <Navbaranonuser />}
      {localStorage.getItem("userName") != null && <Navbar />}

      <div className="flex flex-col justify-center items-center p-3">
        <h2 className="text-3xl font-bold mb-2">{t("update")}</h2>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 justify-center">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("word")}:</strong>
              </label>
              <input
                type="text"
                value={word}
                onChange={(e) => setWord(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("pos")}:</strong>
              </label>
              <select
                value={pos}
                onChange={(e) => {
                  setPos(e.target.value);
                }}
                className="border p-2 w-full"
              >
                <option value="Unknown">{t("unknown")}</option>
                <option value="Noun">{t("noun")}</option>
                <option value="Verb">{t("verb")}</option>
                <option value="Adjective">{t("adjective")}</option>
                <option value="Pronoun">{t("pronoun")}</option>
                <option value="Adverb">{t("adverb")}</option>
                <option value="Preposition">{t("preposition")}</option>
                <option value="Conjunction">{t("conjunction")}</option>
                <option value="Article">{t("article")}</option>
                <option value="Interjection">{t("interjection")}</option>
              </select>
              </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("definition")}:</strong>
              </label>
              <input
                type="text"
                value={definition}
                onChange={(e) => setDefinition(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("example")}:</strong>
              </label>
              <input
                type="text"
                value={example}
                onChange={(e) => setExample(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("context")}:</strong>
              </label>
              <input
                type="text"
                value={field}
                onChange={(e) => setField(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("school")}:</strong>
              </label>
              <input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("attribution")}:</strong>
              </label>
              <input
                type="text"
                value={attribution}
                onChange={(e) => setAttribution(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("status")}:</strong>
              </label>
              <select
                id="status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  // console.log("authusername", auth.userName);
                  setEditor(localStorage.getItem("userName"));
                  setDate_accepted(isoDate);
                }}
                className="w-full p-2 border rounded-lg"
              >
                <option value="ACC">{t("accepted")}</option>
                <option value="PND">{t("pending")}</option>
                <option value="REJ">{t("rejected")}</option>
                <option value="FLG">{t("flagged")}</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("language")}:</strong>
              </label>
              <select
                id="language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="w-full p-2 border rounded-lg"
              >
                <option value="Eng">English</option>
                <option value="Afr">Afrikaans</option>
                <option value="Zul">isiZulu</option>
                <option value="Ses">Sesotho</option>
                <option value="Sep">Sepedi</option>
                <option value="Set">Setswana</option>
                <option value="Xit">XiTsonga</option>
                <option value="Sis">SiSwati</option>
                <option value="Nde">IsiNdebele</option>
                <option value="Xho">IsiXhosa</option>
                <option value="Tsh">TshiVenḓa</option>
                <option value="Por">Portuguese</option>
                <option value="Fre">French</option>
              </select>
            </div>
            <button
              type="submit"
              className="w-full bg-cyan-700 text-white py-2 px-4 rounded-lg hover:bg-cyan-800"
            >
              {t("update")}
            </button>
            <button
              type="reset"
              onClick={(e) => {
                // setWord(word);
                // setDefinition(definition);
                // setExample(example);
                window.history.back();
              }}
              className="w-1/2 mt-6 bg-rose-700 text-white py-2 px-4 rounded-lg hover:bg-rose-800"
            >
              {t("cancel")}
            </button>
          </form>
        </div>
        <div className="flex flex-col justify-center items-center p-4">
          <a href="/" className="inline-block">
            <img src={Logo} alt="Logo" width="100" height="100" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default UpdateWord;
