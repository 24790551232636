import React, { useState, useEffect, useContext } from "react";
import { baseUrl } from "../shared";
import { getValidAccessToken } from "../shared";
import { useTranslation } from "react-i18next";
import "../i18n";
import Logo from "../logo512.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";

const UserRoleManager = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [newGroup, setNewGroup] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  // Fetch the list of users (ensure your backend API provides this)
  useEffect(() => {
    const fetchUsers = async () => {

      // const token = localStorage.getItem("accessToken");


        const token = await getValidAccessToken(); // Get valid token
      // // Die volgende blok kry die groep van die versoeker:
      // const token = localStorage.getItem("accessToken");
      // const response = await fetch(baseUrl + "v1/gebruiker/", {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      // const rouResponse = await response.text();
      // const userdata = JSON.parse(rouResponse);
      // const gebruikersGroep = userdata[0].groups[0];
      // console.log("Gebruikergroep",userdata[0].groups[0])
      // // Tot hier die blok.
      try {
        // console.log("Voor",token);
        // const token = await getValidAccessToken(); // Get valid token
        console.log("Token",token);
        const response = await fetch(baseUrl + "v1/gebruikers/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }); 
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        if (!response.ok) {
          console.log(`Error: ${response.status} - ${response.statusText}`);
          throw new Error(`HTTP status ${response.status}`);
        }

        // Log the raw response as text before parsing
        const rawResponse = await response.text();
        console.log("Raw Response:", rawResponse);

        // After logging, try to parse the raw response as JSON
        const contentType = response.headers.get("content-type");
        console.log("Content-Type:", contentType);

        if (contentType && contentType.includes("application/json")) {
          const data = JSON.parse(rawResponse);
          console.log("Received data:", data);
          // setUsers(data);
          // setUser(data);
          // Create a copy using .map()
          console.log("Users! moet wees",users,user);
          const temp = data.map(user => ({ ...user, "newGroup":user.groups[0] })); // Copy object
          console.log("tempdata",temp)

          setUsers(temp);
          console.log("Users",users)
        } else {
          console.log("Response is not JSON or no content-type set");
          throw new Error("Response is not JSON or content-type is missing");
        }
      } catch (err) {
        console.error("Error occurred:", err);
        // setError("Error fetching users -- please log in again.");
        setError(
          <span className="flex flex-col justify-center items-center p-2">
            {/* Error fetching users — please <Link to="/login">log in again</Link>. */}
            Error fetching users — please{' '}
        <Link to="/login" className="text-blue-500 underline hover:text-blue-700">
          log in 
        </Link> again.
          </span>
        );
      }
    };

    fetchUsers();
  }, []);

  // Function to change user group:
  const handleGroupChange = async (userName, newGroup) => {
    const token = localStorage.getItem("accessToken");
    try {
      const token = await getValidAccessToken(); // Get valid token
      const response = await fetch(baseUrl + "v1/change-group/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          username: userName,
          new_group: newGroup,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        // alert(`Success!: ${data.message}`);
        window.location.reload(false);
        // navigate(`/userroles/`);
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred");
    }
  };
  // Function to change user role  -- not used any more
  const changeUserRole = async (userId) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(baseUrl + `v1/change-role/${userId}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      const data = await response.json();

      if (data.status === "success") {
        // Update role in the users state
        setUsers(
          users.map((user) =>
            user.id === userId ? { ...user, role: data.new_role } : user
          )
        );
      } else {
        alert("Error changing role");
      }
    } catch (error) {
      alert("Error connecting to the server");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {localStorage.setItem("bladsy", "UserRoleManager")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}

      <div className="flex flex-col justify-center items-center p-2">
        <h3 className="text-4xl font-bold mb-6">
          <Link to={"/"}>User Role Manager</Link>
        </h3>
        <table className="border-separate [border-spacing:0.8rem]">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Current Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.username}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{t(user.groups)}</td>
                <td>
                  <select
                    id="role"
                    value={user.newGroup}
                    onChange={(e) => {
                      setNewGroup(e.target.value);
                    }}
                    className="w-full p-2 border rounded-lg"
                  >
                    <option value="r_user">{t("r_user")}</option>
                    <option value="editor">{t("editor")}</option>
                    <option value="administrator">{t("administrator")}</option>
                  </select>
                </td>
                <td>
                  <button
                    onClick={() => handleGroupChange(user.username, newGroup)}
                  >
                    Change
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col justify-center items-center p-4">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" width="100" height="100" />
        </Link>
      </div>
    </div>
  );
};

export default UserRoleManager;
